/*globals $ */

class SpMenu {
  constructor(app) {
    this.app                    = app;
    this.isOpen                 = false;

    this.$window                = $(window);
    this.$container             = $('.SpMenu');
    this.$inner                 = $('.SpMenu__inner');
    this.$btnToggleMenu         = $('.BtnToggleMenu');
    this.$btnOpenRelatePanel    = $('.BtnOpenRelateSp');
    this.$btnCloseRelatePanel   = $('.SpRelatePanel__close button');

    this.$btnToggleMenu.on('click', () => {
      if(this.isOpen) {
        this.close();
      } else {
        this.open();
      }
    });

    this.$btnOpenRelatePanel.on('click', () => {
      $('.SpRelatePanel')
        .stop(false)
        .css({'opacity':0, 'display':'block'})
        .delay(10)
        .animate({'opacity':1}, 400, 'easeOutExpo');
    });

    this.$btnCloseRelatePanel.on('click', () => {
      $('.SpRelatePanel')
        .stop(false)
        .animate({'opacity':0}, 400, 'easeInOutSine', () => {
          $('.SpRelatePanel').css('display', 'none');
        });
    });

    this.$window
      .on('resize', ()=>{
        this.onWindowResize();
      })
      .on('scroll', ()=>{
        this.onWindowScroll();
      });
    this.onWindowResize();
  }

  onWindowScroll() {

  }

  onWindowResize() {
    this.innerHeight = window.innerHeight;
    this.$container.css({'height': this.innerHeight - 56});
    this.onWindowScroll();
  }

  open() {
    if(!this.isOpen) {
      this.isOpen = true;
      this.prevScrollPoint = $(window).scrollTop();

      this.$btnToggleMenu.addClass('-open-menu');
      this.$inner.stop(false).css('bottom', '100%');
      this.$container.stop(false).css('display', 'block');

      setTimeout(() => {
        this.$inner.animate({'bottom': 0}, 300, 'easeOutExpo', () => {
          $('html, body').css({
            'height': '100%',
            'overflow': 'hidden'
          });
        });
      }, 50);
    }
  }

  close() {
    if(this.isOpen) {
      this.isOpen = false;
      this.$btnToggleMenu.removeClass('-open-menu');

      $('html').css({
        'height': '100%',
        'overflow': 'auto'
      });

      $('body').css({
        'height': 'auto',
        'overflow-y': 'scroll',
      });

      setTimeout(() => {
        $('html, body').scrollTop(this.prevScrollPoint);
      }, 30);

      setTimeout(() => {
        this.$inner.stop(false).animate({'bottom': '100%'}, 300, 'easeOutExpo', () => {
          this.$container.stop(false).css('display', 'none');
        });
      }, 40);
    }

  }
}

export default SpMenu;
