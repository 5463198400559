class SubProjectsMap {
  constructor() {
    this.waitInterval = setInterval(() => {
      if(window.onLoadGoogleMapApi == true) {
        clearInterval(this.waitInterval);
        this.init();
      }
    }, 1000 / 30);
  }



  init() {
    const google = window.google;

    this.locations = [];

    this.options = {
      'fullscreenControl'   : false,
      'mapTypeControl'      : false,
      'streetViewControl'   : false
    };

    this.map = new google.maps.Map(document.getElementById('map'), this.options);


    for(let i = 0; i < window.data_sublocations.length; i++) {
      this.locations.push({
        lat: Number(window.data_sublocations[i]['lat']),
        lng: Number(window.data_sublocations[i]['lng'])
      });
    }


    var minX = this.locations[0]['lng']//marker[0].getPosition().lng();
    var minY = this.locations[0]['lat']//marker[0].getPosition().lat();
    var maxX = this.locations[0]['lng'];//marker[0].getPosition().lng();
    var maxY = this.locations[0]['lng'];//marker[0].getPosition().lat();

    var markers = this.locations.map( (location, i) => {
      var _marker = new google.maps.Marker({
        'position'  : location,
        'label'     :'',
        'icon'      : {
          'url'     : '/cms/wp-content/themes/nipponia/assets/images/common/gmap/def.png',
          'size'    : new google.maps.Size(28, 40),
          'origin'  : new google.maps.Point(0, 0),
          'anchor'  : new google.maps.Point(14, 40)
        }
      });
      if(i == 0) {
        minX = _marker.getPosition().lng();
        minY = _marker.getPosition().lat();
        maxX = _marker.getPosition().lng();
        maxY = _marker.getPosition().lat();
      } else {
        var lt = _marker.getPosition().lat();
        var lg = _marker.getPosition().lng();
        if (lg <= minX){ minX = lg; }
        if (lg > maxX){ maxX = lg; }
        if (lt <= minY){ minY = lt; }
        if (lt > maxY){ maxY = lt; }
      }

      return _marker;
    });



    if(this.locations.length == 1) {
      this.map.setCenter(new google.maps.LatLng(this.locations[0]['lat'], this.locations[0]['lng']));
      this.map.setZoom(15);
    } else {
      var sw = new google.maps.LatLng(maxY, minX);
      var ne = new google.maps.LatLng(minY, maxX);
      var bounds = new google.maps.LatLngBounds(sw, ne);
      this.map.fitBounds(bounds);
    }

    const MarkerClusterer = window.MarkerClusterer;

    // var markerCluster = new MarkerClusterer(this.map, markers,
    new MarkerClusterer(this.map, markers,
        {
          styles: [
            {
              url: '/cms/wp-content/themes/nipponia/assets/images/common/gmap/m5.png',
              height: 60,
              width: 60,
              textColor: '#FFF',//'rgba(0, 0, 0, 1)',
              textSize: 16
            },
            {
              url: '/cms/wp-content/themes/nipponia/assets/images/common/gmap/m5.png',
              height: 75,
              width: 75,
              textColor: '#FFF',//'rgba(0, 0, 0, 1)',
              textSize: 16
            },
            {
              url: '/cms/wp-content/themes/nipponia/assets/images/common/gmap/m5.png',
              height: 90,
              width: 90,
              textColor: '#FFF',//'rgba(0, 0, 0, 1)',
              textSize: 16
            },

          ]
        }
      );
  }
}

export default SubProjectsMap;
