/*globals $ */

class MenuItem {
  constructor(app, $el) {
    this.app        = app;
    this.$el        = $el;
    this.$pulldown  = this.$el.find('.PulldownMenu');
    this.$subUl     = this.$pulldown.children('ul');

    this.isOpen     = false;

    if(this.$el.find('.PulldownMenu').length) {
      if($('html').hasClass('ua-desktop')) {
        this.$el.mouseover(() => {
          clearInterval(this.outInterval);
          this.app.isHoverMenuItem = true;
          if(this.isOpen == false) {
            this.isOpen = true;
            this.$pulldown.css('height', this.$subUl.height());

            $('.Header').addClass('-hover');
          }
        });

        this.$el.mouseout(() => {
          clearInterval(this.outInterval);
          if(this.isOpen) {
            this.app.isHoverMenuItem = false;
          }
          this.outInterval = setInterval(() => {
            clearInterval(this.outInterval);

            this.isOpen = false;
            this.$pulldown.css('height', 0);

            if(!this.app.isHoverMenuItem) {
              $('.Header').removeClass('-hover');
            }

          }, 100);
        });
      }
    }

  }
}

export default MenuItem;
