/*globals $,google */

class ProjectsMap {
  constructor(app, isArchivePage = false) {

    this.app = app;
    this.isArchivePage = isArchivePage;
    this.waitInterval = setInterval(() => {
      if(window.onLoadGoogleMapApi == true) {
        clearInterval(this.waitInterval);
        this.init();
      }
    }, 1000 / 30);
  }

  fadeinCard($target) {
    $target.css('display', 'block');
    $target.find('.ProjectCard__visual').css('opacity', 0);
    $target.find('.ProjectCard__area').css('opacity', 0);
    $target.find('.ProjectCard__title').css('opacity', 0);
    $target.find('.TagCloud').css('opacity', 0);

    if(!$target.find('.ProjectCard__visual').hasClass('-load-complete')) {
      let src = $target.find('.ProjectCard__visual').attr('data-original');
      $('<img>')
        .one('load', () => {
          $target.find('.ProjectCard__visual').css('background-image', "url('" + src + "')");
          $target.find('.ProjectCard__visual').addClass('-load-complete');

          $target.find('.ProjectCard__visual').animate({'opacity': 1}, 500, 'easeInOutExpo');
          $target.find('.ProjectCard__area').delay(100).animate({'opacity': 1}, 500, 'easeInOutExpo');
          $target.find('.ProjectCard__title').delay(200).animate({'opacity': 1}, 500, 'easeInOutExpo');
          $target.find('.TagCloud').delay(300).animate({'opacity': 1}, 500, 'easeInOutExpo');
        })
        .attr('src', src);
    } else {
      $target.find('.ProjectCard__visual').animate({'opacity': 1}, 500, 'easeInOutExpo');
      $target.find('.ProjectCard__area').delay(100).animate({'opacity': 1}, 500, 'easeInOutExpo');
      $target.find('.ProjectCard__title').delay(200).animate({'opacity': 1}, 500, 'easeInOutExpo');
      $target.find('.TagCloud').delay(300).animate({'opacity': 1}, 500, 'easeInOutExpo');
    }
  }

  init() {
    this.options = {
      'fullscreenControl'   : false,
      'mapTypeControl'      : false,
      'streetViewControl'   : false
    };
    this.map = new google.maps.Map(document.getElementById('map'), this.options);
    this.$cards = [];
    this.locations = [];
    this.isShowInfo = false;


    $('.ProjectsMapInfo .ProjectCard').each((i, e) => {
      this.$cards.push($(e));
    });
    this.$cards[this.$cards.length - 1].css('display', 'block');

    for(let i = 0; i < window.data_area.length; i++) {
      this.locations.push({
        lat: Number(window.data_area[i]['lat']),
        lng: Number(window.data_area[i]['lng'])
      });
    }



    $('.BtnCloseMapInfo').on('click', () => {
      if(this.isShowInfo) {
        this.isShowInfo = false;

        if(this.app.viewMode == 'pc') {
          $('.ProjectsMapEmbed').stop(false).animate({'width':'100%'}, 400, 'easeOutExpo');
          $('.ProjectsMapInfo').stop(false).animate({'left':'100%'}, 400, 'easeOutExpo');
        } else if(this.app.viewMode == 'sp') {
          $('.ProjectsMapEmbed').stop(false).animate({'height':'100%'}, 400, 'easeOutExpo');
          $('.ProjectsMapInfo').stop(false).animate({'top':'100%'}, 400, 'easeOutExpo');
        }


      }
    });




    var changeCard = (targetId) => {

      // console.log($('.ProjectsMapInfo').css('left'));
      if(this.isShowInfo == false) {
        this.isShowInfo = true;
        // console.log(this.app.viewMode);

        if(this.app.viewMode == 'pc') {
          $('.ProjectsMapEmbed').stop(false)
            .css({
              'width': '100%',
              'height': '100%'
            })
            .animate({'width':'50%'}, 400, 'easeOutExpo');
          // $('.ProjectsMapEmbed').stop(false).css('height', '100%').animate({'width':'50%'}, 400, 'easeOutExpo');
          $('.ProjectsMapInfo').stop(false)
            .css({
              'top': '0',
              'left': '100%',
              'height': '100%'
            })
            .delay(10)
            .animate({'left':'50%'}, 400, 'easeOutExpo');
        } else if(this.app.viewMode == 'sp') {
          $('.ProjectsMapEmbed').stop(false)
            .css({
              'width': '100%',
              'height': '100%'
            })
            .animate({'height':'50%'}, 400, 'easeOutExpo');
          // $('.ProjectsMapEmbed').stop(false).css('width', '100%').animate({'height':'50%'}, 400, 'easeOutExpo');
          // $('.ProjectsMapInfo').stop(false).css('top', '0').animate({'left':'50%'}, 400, 'easeOutExpo');


          $('.ProjectsMapInfo').stop(false)
            .css({
              'top': '100%',
              'left': '0',
              'height': '50%'
            })
            .delay(10)
            .animate({'top':'50%'}, 400, 'easeOutExpo');

          // $('.ProjectsMapEmbed').stop(false).animate({'height':'50%'}, 400, 'easeOutExpo');
          // $('.ProjectsMapInfo').stop(false).animate({'left':'50%'}, 400, 'easeOutExpo');
        }

      }

      for(let i = 0; i < this.$cards.length; i++) {
        if(this.$cards[i].attr('data-id') == targetId) {
          this.fadeinCard(this.$cards[i]);
        } else {
          if(this.$cards[i].css('display') == 'block') {
            this.$cards[i].css('display', 'none');
          }
        }
      }
    };


    var minX = this.locations[0]['lng'];//marker[0].getPosition().lng();
    var minY = this.locations[0]['lat'];//marker[0].getPosition().lat();
    var maxX = this.locations[0]['lng'];//marker[0].getPosition().lng();;
    var maxY = this.locations[0]['lng'];//marker[0].getPosition().lat();;

    var markers = this.locations.map( (location, i) => {
      var _marker = new google.maps.Marker({
        'position'  : location,
        'label'     :'',
        'icon'      : {
          'url'     : '/cms/wp-content/themes/nipponia/assets/images/common/gmap/def.png',
          'size'    : new google.maps.Size(28, 40),
          'origin'  : new google.maps.Point(0, 0),
          'anchor'  : new google.maps.Point(14, 40)
        }
      });
      if(i == 0) {
        minX = _marker.getPosition().lng();
        minY = _marker.getPosition().lat();
        maxX = _marker.getPosition().lng();
        maxY = _marker.getPosition().lat();
      } else {
        var lt = _marker.getPosition().lat();
        var lg = _marker.getPosition().lng();
        if (lg <= minX){ minX = lg; }
        if (lg > maxX){ maxX = lg; }
        if (lt <= minY){ minY = lt; }
        if (lt > maxY){ maxY = lt; }
      }
      // console.log('check ', i);




      _marker['customInfo'] = 'id-' + window.data_area[i]['id'];

      google.maps.event.addListener(_marker, 'click', function() {
        this.map.setZoom(14.0);
        this.map.setCenter(_marker.getPosition());
        changeCard( this.customInfo.slice(3) );
      });

      return _marker;
    });

    var sw = new google.maps.LatLng(maxY, minX);
    var ne = new google.maps.LatLng(minY, maxX);
    var bounds = new google.maps.LatLngBounds(sw, ne);
    this.map.fitBounds(bounds);

    const MarkerClusterer = window.MarkerClusterer;

    // var markerCluster = new MarkerClusterer(this.map, markers,
    new MarkerClusterer(this.map, markers,
        {
          styles: [
            {
              url: '/cms/wp-content/themes/nipponia/assets/images/common/gmap/m5.png',
              height: 60,
              width: 60,
              textColor: '#FFF',//'rgba(0, 0, 0, 1)',
              textSize: 16
            },
            {
              url: '/cms/wp-content/themes/nipponia/assets/images/common/gmap/m5.png',
              height: 75,
              width: 75,
              textColor: '#FFF',//'rgba(0, 0, 0, 1)',
              textSize: 16
            },
            {
              url: '/cms/wp-content/themes/nipponia/assets/images/common/gmap/m5.png',
              height: 90,
              width: 90,
              textColor: '#FFF',//'rgba(0, 0, 0, 1)',
              textSize: 16
            },

          ]
        }
      );
  }
}

export default ProjectsMap;
