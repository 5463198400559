/*globals $ */

class ProjectContentAccordion {
  constructor($el) {
    this.$el              = $el;
    this.$toggle          = this.$el.find('.ProjectContentAccordionToggle');
    this.$head            = this.$el.find('.ProjectContentAccordion__head');
    this.$collapse        = this.$el.find('.ProjectContentAccordionCollapse');
    this.$heightContent   = this.$el.find('.ProjectContentAccordionCollapse__height');

    this.$el.find('.ProjectContentAccordionCollapse__height p').each((i, e) => {
      if($(e).children('span')) {
        if(String($(e).children('span').attr('id')).indexOf('more-') != -1) {
          $(e).remove();
        }
      }
    });

    this.$toggle.on('click', () => {
      let childMarginTop = parseInt(this.$heightContent.children('*:first').css('margin-top'));
      this.$collapse.animate({'height': this.$heightContent.height() + childMarginTop}, 900, 'easeInOutExpo', () => {
        this.$collapse.css('height', 'auto');
      });
      this.$el.animate({'padding-top':20}, 900, 'easeInOutExpo');
      this.$head.animate({'opacity':0}, 700, 'easeOutExpo', () => {
        this.$head.remove();
      });
    });
  }
}

export default ProjectContentAccordion;
