/*globals $ */

class ModalGallery {

  constructor(app) {

    this.app            = app;
    this.$container     = $('.ModalGallery');
    this.$btnClose      = this.$container.find('.ModalGallery__close button');
    this.$carousel      = this.$container.find('.ModalGallerySlider');

    this.isOpen         = false;
    this.imageNum       = $('.ModalGallerySlideItem').length;
    this.loadedNum      = 0;
    this.onLoadComplete = false;

    this.slideOptions   = {
      dots              : false,
      infinite          : true,
      autoplay          : false,
      arrows            : true,
      slidesToShow      : 1,
      slidesToScroll    : 1,
      dots              : false,
      infinite          : true,
    };
  }

  init() {

    $('.ModalGallerySlideItemImg').each((i, e) => {
      $(e).prepend('<img src="' + $(e).attr('data-img') + '">');
    });

    this.$carousel.on('init', () => {
    }).on('breakpoint', () => {
    }).slick(this.slideOptions);

    $('.ModalGallerySlideItem').each((i, e) => {
      $(e).find('img').one('load', (e_img) => {
        let loadInterval = setInterval(() => {
          if(e_img.target.naturalWidth != 0) {
            clearInterval(loadInterval);

            $(e_img.currentTarget).attr('data-ratio', e_img.target.naturalWidth / e_img.target.naturalHeight);
            $(e).addClass('-loaded');
            this.loadedNum++;

            if(this.loadedNum == this.imageNum) {
              this.start();
            }
          }
        }, 1000 / 60);
      }).load();
    });
  }

  bind() {
    $(window).on('keydown', (e) => {
      if(this.isOpen) {
        if(e.keyCode == 37) {
          this.$carousel.slick('slickPrev');
        } else if(e.keyCode == 39) {
          this.$carousel.slick('slickNext');
        } else if(e.keyCode == 27) {
          this.close();
        }
      }
    });

    this.$btnClose.on('click', () => {
      this.close();
    });

    $('.ModalGallerySlideItem__bg').on('click', () => {
      this.close();
    });

    $('.js-open-modalgallery').on('click', (e) => {
      this.open(parseInt($(e.currentTarget).attr('data-index')));
    });
  }

  start() {
    this.onLoadComplete = true;
    this.onWindowResize();
    this.bind();

    $('.ModalGallery').css({
      'display'         : 'none',
      'pointer-events'  : 'auto'
    });
  }

  open(target) {
    if(!this.isOpen) {
      this.isOpen = true;
      this.$container
        .stop(false)
        .css({'opacity':0, 'display':'block'});
      setTimeout(() => {
        this.onWindowResize();
        this.$container.animate({'opacity':1}, 400, 'easeInOutSine');
      }, 30);

      this.onWindowResize();
      this.$carousel.slick('slickGoTo', target, true);
    } else {
      this.onWindowResize();
      this.$carousel.slick('slickGoTo', target, true);
    }

  }

  close() {
    if(this.isOpen) {
      this.isOpen = false;
      this.$container
        .stop(false)
        .animate({'opacity': 0}, 300, 'easeInOutSine', () => {
          this.$container.css('display', 'none');
        });
    }
  }

  onWindowResize() {
    if(this.isOpen || !this.onLoadComplete) {
      $('.ModalGallerySlideItem').each((i, e) => {
        if($(e).hasClass('-loaded')) {
          let $imgContainer     = $(e).children('.ModalGallerySlideItem__rect');
          let containerWidth    = $imgContainer.width();
          let containerHeight   = $imgContainer.height();
          let containerRatio    = containerWidth / containerHeight;
          let imgRatio          = Number($imgContainer.find('img').attr('data-ratio'));

          if(containerRatio > imgRatio) {
            $imgContainer.find('img').css({
              'height': containerHeight,
              'width': containerHeight * imgRatio
            });
          } else {
            $imgContainer.find('img').css({
              'width': containerWidth,
              'height': containerWidth / imgRatio
            });
          }
        }
      });
    }

  }
}

export default ModalGallery;
