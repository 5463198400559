import "../scss/style.scss";

import jQuery from "jquery";
const $ = jQuery;

window.jQuery = $;
window.$ = $;

import "cssuseragent";
import "jquery.easing";
import "slick-carousel";

import MenuItem from "./module/MenuItem";
import socialButton from "./module/Social";
import SlickSlider from "./module/SlickSlider";
import ModalGallery from "./module/ModalGallery";
import TopSlide from "./module/TopSlide";
import ProjectsMap from "./module/ProjectsMap";
import SubProjectsMap from "./module/SubProjectsMap";
import RelatePanel from "./module/RelatePanel";
import SpMenu from "./module/SpMenu";
import SynergyController from "./module/SynergyController";
import AreaPageController from "./module/AreaPageController";
import ProjectContentAccordion from "./module/ProjectContentAccordion";
import ImgLazyLoad from "./module/img-lazy-load";

class JoinHeaderTitleBg {
  constructor() {
    this.$el = $(".JoinHeaderTitle__bg");
    this.$parent = $(".JoinHeaderTitle__bg").parent();
  }
  onWindowResize() {
    this.$el.css({
      left: -this.$parent.offset()["left"],
      width: this.$parent.width() + this.$parent.offset()["left"] + 25,
    });
  }
}

class SpHasChildMenu {
  constructor($el) {
    this.isOpen = false;

    this.$el = $el;
    this.$btn = this.$el.find(".BtnToggleAccordion");
    this.$wrap = this.$el.find(".SpMenuAccordion");
    this.$heightObject = this.$el.find(".SpMenuAccordion__height");

    this.$btn.on("click", () => {
      if (this.isOpen) {
        this.close();
      } else {
        this.open();
      }

      return false;
    });
  }

  open() {
    if (!this.isOpen) {
      this.$el.addClass("-open");
      this.isOpen = true;
      this.$wrap.css("height", this.$heightObject.height());
    }
  }

  close() {
    if (this.isOpen) {
      this.$el.removeClass("-open");
      this.isOpen = false;
      this.$wrap.css("height", 0);
    }
  }
}

class PageHome {
  constructor(app) {
    this.app = app;

    this.$header = $(".Header");

    // setTimeout();

    this.topSlide = new TopSlide(this);
  }
  fadein() {
    // リンク部分のフェードイン準備
    this.copyHeight = $(".TopSlideCopyLinkHeight").height() + 1;
    $(".TopSlideCopyLink").css({
      height: 0,
      overflow: "hidden",
      opacity: 1,
    });

    /*
    $('.MenuItem.-has-pulldown a').on('mouseover', () => {
      console.log('hover');
    });
    $('.MenuItem.-has-pulldown a').on('mouseout', () => {
      console.log('out');
    });
    */

    setTimeout(() => {
      // コピーのフェードイン
      $(".TopSlideCopy").addClass("-fadein");
      $(".SpSlideInfoWrap").addClass("-fadein");
    }, 2500);

    setTimeout(() => {
      $(".SlideInfo").addClass("-fadein");

      $(".TopSlideCopyLink").animate(
        { height: this.copyHeight },
        1000,
        "easeInOutExpo",
        () => {
          this.startFadeinObject();
          $(".TopSlideCopyLink").css({
            height: "auto",
            overflow: "visible",
          });
          $(".TopSlideCopyLink").addClass("-underline");
          $("body").css("pointer-events", "auto");
        }
      );
    }, 3000);
  }
  startFadeinObject() {
    $(".fadeinObject").each((i, e) => {
      setTimeout(() => {
        $(e).addClass("-show");
      }, 300 * parseInt($(e).attr("data-showindex")));
    });
  }
  onWindowScroll() {}
  onWindowResize() {
    /*
    console.log(this.$header.height());
    if($('.wrapper').css('padding-top') == 0) {
      if(!this.app.isSmallHeader) {
        this.$header.removeClass('Header--small');
      }
    } else {
      this.$header.addClass('Header--small');
    }
    */

    if (this.app.isTablet) {
      this.$header.addClass("Header--small");
    } else {
      if (this.app.isSmallHeader) {
        this.$header.addClass("Header--small");
      } else {
        if (!this.app.relatePanel.isOpen) {
          this.$header.removeClass("Header--small");
        }
      }
    }

    /*
    if(this.$header.height() <= 128) {
      this.$header.addClass('Header--small');
    } else {
      if(!this.app.isSmallHeader) {
        this.$header.removeClass('Header--small');
      }
    }*/
  }
  onLoad() {}
}
class PageCommon {
  constructor(app) {
    this.app = app;
  }
  onWindowScroll() {}
  onWindowResize() {}
  onLoad() {}
}

class MainApp {
  constructor() {
    this.viewMode = "pc";
    this.isTablet = false;

    if (Number($(".check-res").width()) > 900) {
      this.viewMode = "pc";
    } else {
      this.viewMode = "sp";
    }
    if (Number($(".check-tablet").width()) > 960) {
      this.isTablet = true;
    } else {
      this.isTablet = false;
    }

    if ($("body").hasClass("home")) {
      this.page = new PageHome(this);
    } else {
      this.page = new PageCommon(this);
    }

    /*
    $('.js-pagetop').on('click', () => {
      $('html, body').animate({'scrollTop': 0}, 1000, 'easeInOutExpo');
      return false;
    });
    */

    $(".js-anchor").on("click", (e) => {
      if ($("#" + $(e.currentTarget).attr("data-target")).length) {
        let target = $("#" + $(e.currentTarget).attr("data-target")).offset()[
          "top"
        ];
        if (this.viewMode == "pc") target -= 71;
        else if (this.viewMode == "sp") target -= 55;

        if (
          this.viewMode == "pc" &&
          target > $(".Footer").offset()["top"] - $(window).height()
        ) {
          target = $(".Footer").offset()["top"] - $(window).height();
        }
        $("html, body")
          .stop(false)
          .animate({ scrollTop: target }, 700, "easeInOutExpo");
      }
    });

    $("a").on("click", (e) => {
      let href = $(e.currentTarget).attr("href");
      if (href.indexOf("nipponia") != -1) {
        if (
          href.indexOf("/about/#") != -1 &&
          String(location.href).indexOf("/about") != -1
        ) {
          let target = $(
            "#section-" + href.slice(href.indexOf("#") + 1)
          ).offset()["top"];

          if (this.viewMode == "pc") target -= 71;
          else if (this.viewMode == "sp") target -= 55;

          if (
            this.viewMode == "pc" &&
            target > $(".Footer").offset()["top"] - $(window).height()
          ) {
            target = $(".Footer").offset()["top"] - $(window).height();
          }
          $("html, body")
            .stop(false)
            .animate({ scrollTop: target }, 700, "easeInOutExpo");
          return false;
        }
      }
    });

    // シェアボタン
    $(".js-btn-share").on("click", (e) => {
      let shareText = document.title;
      if (!$("body").hasClass("home")) {
        shareText = shareText.replace(
          "NIPPONIA - なつかしくて、あたらしい、日本の暮らしをつくる",
          "NIPPONIA"
        );
      }

      socialButton(
        $(e.currentTarget).attr("data-type"),
        location.href,
        shareText
      );
      return false;
    });

    this.$window = $(window);
    this.$header = $(".Header");

    this.isHome = false;
    this.isSmallHeader = false;
    this.scrollTop = 0;

    this.spMenu = new SpMenu(this);

    $(".SpHasChildMenu").each((i, e) => {
      new SpHasChildMenu($(e));
    });

    this.relatePanel = new RelatePanel(this);

    $(".js-open-relate").on("click", () => {
      if (this.relatePanel.isOpen) {
        this.relatePanel.close();
      } else {
        this.relatePanel.open();
      }

      return false;
    });

    // 画像遅延読み込み
    this.imgLazyLoad = new ImgLazyLoad(this);
    this.isHoverMenuItem = false;

    $(".MenuItem").each((i, e) => {
      new MenuItem(this, $(e));
    });

    if ($(".js-projectmap").length) {
      new ProjectsMap(this);
    }

    if ($(".js-subprojectsmap").length) {
      new SubProjectsMap();
    }

    if ($("body").hasClass("post-type-archive-areas")) {
      new AreaPageController(this);
    }

    this.joinHeaderTitleBg = undefined;
    if ($(".JoinHeaderTitle__bg").length) {
      this.joinHeaderTitleBg = new JoinHeaderTitleBg();
    }

    if ($("body").hasClass("home")) {
      this.isHome = true;
      // this.topSlide = new TopSlide();
    }

    /*
    $(window).on('keydown', (e) => {
      if(e.keyCode == 76) {
        if($('.GridWrap').css('display') == 'none') {
          $('.GridWrap').css('display', 'block');
        } else {
          $('.GridWrap').css('display', 'none');
        }
      }
    });
    */

    if ($("body").hasClass("page-template-page-inquiry")) {
      if (
        String(location.search).length > 1 &&
        String(location.search).indexOf("?target=") != -1
      ) {
        let _target = String(location.search).slice(8);

        if (_target == "areamanager") {
          $('select[name="synergy-ext1"]').val(
            "求人・エリアマネージャーについて"
          );
        } else if (_target == "craftsmanship") {
          $('select[name="synergy-ext1"]').val(
            "古民家の修復、再生について(建築士・工務店の方)"
          );
        } else if (_target == "investment") {
          $('select[name="synergy-ext1"]').val("寄付、投資について");
        } else if (_target == "owner") {
          $('select[name="synergy-ext1"]').val("古民家等の活用について");
        } else if (_target == "vendor") {
          $('select[name="synergy-ext1"]').val(
            "開業・出店について(物件をお探しの方)"
          );
        }
      }
    }

    if ($(".ProjectContentAccordion").length) {
      $(".ProjectContentAccordion").each((i, e) => {
        new ProjectContentAccordion($(e));
      });
    }

    $(".BlogCard").on("click", (e) => {
      location.href = $(e.currentTarget).attr("data-permalink");
    });

    $(".js-btn-pagetop").on("click", () => {
      $("html, body").animate({ scrollTop: 0 }, 1000, "easeInOutExpo");
      return false;
    });

    this.$window
      .on("resize", () => {
        this.onWindowResize();
      })
      .on("scroll", () => {
        this.onWindowScroll();
      });
    this.onWindowResize();

    if ($(".slick-slider").length) {
      new SlickSlider(this);
    }

    if ($(".ModalGallery").length) {
      this.modalGallery = new ModalGallery(this);
    }
  }

  onWindowScroll() {
    this.scrollTop = this.$window.scrollTop();

    if (this.isHome) {
      // トップ

      if (this.relatePanel.isOpenComplete) {
        // パネルオープン時

        if (this.scrollTop > this.relatePanel.panelHeight) {
          $(".Header").css("margin-top", 0);
          $(".Header").removeClass("Header--open-relate");
        } else {
          $(".Header").css("margin-top", this.relatePanel.panelHeight);
          $(".Header").addClass("Header--open-relate");
        }
      } else {
        // パネルクローズ時
        if (!this.relatePanel.isOpen) {
          if (this.scrollTop <= 50) {
            if (this.isSmallHeader == true) {
              this.isSmallHeader = false;

              if (this.isTablet) {
                $(".Header").addClass("Header--small");
              } else {
                $(".Header").removeClass("Header--small");
              }

              // $('.Wrapper').removeClass('Wrapper--header-small');
              // $('body').css('background-color', '#FFF');
            }
          } else {
            if (this.isSmallHeader == false) {
              this.isSmallHeader = true;
              $(".Header").addClass("Header--small");
              // $('.Wrapper').addClass('Wrapper--header-small');
              // $('body').css('background-color', '#F3F3F3');
            }
          }
        }
      }
    } else {
      // トップ以外
      if (this.relatePanel.isOpenComplete) {
        if (this.scrollTop > this.relatePanel.panelHeight) {
          $(".Header").css("margin-top", 0);
          $(".Header").removeClass("Header--open-relate");
        } else {
          $(".Header").css("margin-top", this.relatePanel.panelHeight);
          $(".Header").addClass("Header--open-relate");
        }
      }
    }

    this.imgLazyLoad.update(this.scrollTop, this.windowHeight);

    this.page.onWindowScroll();
  }

  onWindowResize() {
    this.windowWidth = this.$window.width();
    this.windowHeight = this.$window.height();

    if (Number($(".check-res").width()) > 900) {
      this.viewMode = "pc";
    } else {
      this.viewMode = "sp";
    }
    if (Number($(".check-tablet").width()) > 960) {
      this.isTablet = false;
    } else {
      this.isTablet = true;
    }
    // console.log(this.viewMode);

    this.relatePanel.onWindowResize();

    if (this.joinHeaderTitleBg != undefined) {
      this.joinHeaderTitleBg.onWindowResize(this.windowWidth);
    }

    if (this.modalGallery != undefined) {
      this.modalGallery.onWindowResize();
    }

    this.onWindowScroll();
    this.page.onWindowResize();
  }

  onLoad() {
    let href = location.href;
    let hasHashLink = false;
    if (href.indexOf("nipponia") && href.indexOf("/about/#") != -1) {
      hasHashLink = true;
      $("body").addClass("hash-link");
      let target_id = href.slice(href.indexOf("#") + 1);
      let target = $("#section-" + target_id).offset()["top"];

      if (this.viewMode == "pc") target -= 71;
      else if (this.viewMode == "sp") target -= 55;

      if (
        this.viewMode == "pc" &&
        target > $(".Footer").offset()["top"] - $(window).height()
      ) {
        target = $(".Footer").offset()["top"] - $(window).height();
      }
      $("html, body").animate({ scrollTop: target }, 700, "easeInOutExpo");
    }

    if ($(".CommonHeader").length) {
      $(".CommonHeader").addClass("-fadein");

      let contentFadeinDelay = 1300;
      if (hasHashLink) {
        contentFadeinDelay = 0;
      }
      setTimeout(() => {
        $(".AfterFadeinArea").css("opacity", 1);
      }, contentFadeinDelay);
    }

    if ($(".JoinHeader").length) {
      $(".JoinHeader").addClass("-fadein");

      setTimeout(() => {
        $(".AfterFadeinArea").css("opacity", 1);
      }, 300);
    }

    if ($("body").hasClass("single-areas")) {
      if (this.modalGallery != undefined) {
        this.modalGallery.init();
      }
      $(".Keyvisual").addClass("-fadein");

      setTimeout(() => {
        $(".AfterFadeinArea").css("opacity", 1);
      }, 1300);
    }

    $(".slick-arrow").each((i, e) => {
      $(e).addClass("RoundBtn");
    });

    $(".RoundBtn").each((i, e) => {
      $(e).prepend("<span></span>");
    });
  }
}

let mainApp = undefined;

$("document").ready(() => {
  $.ajax({
    type: "get",
    url:
      window.url_template + "/assets/images/symbol-defs.svg?" + Math.random(),
  }).done((data) => {
    let svg = $(data).find("svg");
    $("body").append(svg);
  });

  let synergy = new SynergyController();

  document.addEventListener(
    "wpcf7submit",
    (event) => {
      switch (event.detail.status) {
        case "wpcf7c_confirmed":
          // 確認画面遷移時の処理
          break;

        case "mail_sent":
          synergy.regist();
          break;

        case "validation_failed":
          // エラー時の処理
          break;
      }
    },
    false
  );

  mainApp = new MainApp();
});

$(window).on("load", () => {
  mainApp.onLoad();
});
