/*globals $ */

class RelatePanel {
  constructor(app) {
    this.app              = app;
    this.isOpen           = false;
    this.isOpenComplete   = false;

    this.$header          = $('.Header');
    this.$content         = $('.RelatePanelContent');

    $('.RelatePanel__close button').on('click', () => {
      this.close();
    });
  }

  open() {
    if(!this.isOpen) {
      $('body').addClass('open-relatepanel');
      this.isOpenComplete   = false;
      this.isOpen           = true;
      this.panelHeight      = this.$content.height();

      if(this.app.isHome) {
        if(!this.isSmallHeader) {
          this.isSmallHeader = true;
          $('.Header').addClass('Header--small');
        }
      }

      if(this.app.scrollTop != 0) {
        $('html, body').animate({'scrollTop': 0}, 400, 'easeOutExpo', () => {
          this.openSlidePanel();
        });
      } else {
        this.openSlidePanel();
      }

    } else {
      if(this.app.scrollTop != 0) {
        $('html, body').animate({'scrollTop': 0}, 400, 'easeOutExpo');
      }
    }
  }

  openSlidePanel() {
    this.$header.addClass('Header--open-relate');

    setTimeout(() => {
      this.$header.stop(false).animate({'margin-top': this.panelHeight}, 900, 'easeInOutExpo');
      $('body').stop(false).animate({'padding-top': this.panelHeight}, 900, 'easeInOutExpo', () => {
        this.isOpenComplete = true;
      });
    }, 30);

    setTimeout(() => {
      this.app.onWindowResize();
    }, 100);
  }


  close() {
    $('body').removeClass('open-relatepanel');
    this.isOpenComplete   = false;
    this.isOpen           = false;

    this.$header.removeClass('Header--open-relate');
    this.$header.stop(false).animate({'margin-top': 0}, 400, 'easeOutExpo');

    $('body').stop(false).animate({'padding-top': 0}, 400, 'easeOutExpo');

    $('html, body').animate({'scrollTop': 0}, 400, 'easeOutExpo', () => {
      if(this.app.isHome) {
        if(this.isSmallHeader) {
          this.isSmallHeader = false;
          if(this.app.isTablet == false) {
            $('.Header').removeClass('Header--small');
          }

        }
      }
    });
  }

  onWindowResize() {
    if(this.isOpen && this.isOpenComplete) {
      this.panelHeight = this.$content.height();
      this.$header.css('margin-top', this.panelHeight);
      $('body').css('padding-top', this.panelHeight);
    }
  }
}


export default RelatePanel;
