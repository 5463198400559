/*globals $ */

class TopSlide {
  constructor(pageApp) {

    this.pageApp          = pageApp;
    this.$dataElement     = $('.TopSlideData');

    this.data             = [];
    this.onLoadComplete   = false;
    this.currentImg       = 0;
    this.mapIndex         = 0;
    this.loadSequenceNum  = -1;
    this.isRetina         = false;

    let retinaCheck = window.devicePixelRatio;

    if(retinaCheck >= 2) {

      this.isRetina = true;
    }

    this.$dataElement.find('.TopSlideDataItem').each((i, e) => {


      // let description = String($(e).children('.TopSlideDataItem__description').html()).split('<br>');
      // console.log(description);

      let _data = {
        'img'             : $(e).attr('data-img'),
        'mapImg'          : $(e).attr('data-img-map'),
        'title'           : $(e).children('.TopSlideDataItem__title').html(),
        'address'         : $(e).children('.TopSlideDataItem__address').html(),
        'area'            : $(e).children('.TopSlideDataItem__area').html(),
        'description'     : $(e).children('.TopSlideDataItem__description').html(),
        'isLoadComplete'  : false
      };

      if(this.isRetina && $(e).attr('data-img-retina') != '' && this.pageApp.app.viewMode != 'sp') {
        _data['img'] = $(e).attr('data-img-retina');
      }
      this.data.push(_data);
    });

    for(let i = 0; i < this.data.length; i++) {
      $('.SlideInfoMap').append($('<img src="' + this.data[i]['mapImg']  + '" alt="" data-index="' + i + '">'));
    }

    $('.SlideInfoPager__total').text(this.data.length);

    this.loadSequence();
  }

  loadSequence() {
    if(this.loadSequenceNum + 1 < this.data.length) {
      this.loadSequenceNum++;
      if(this.loadSequenceNum == 2) {
        this.onLoadComplete = true;
        this.start();
      }
      $('<img src="' + this.data[this.loadSequenceNum]['img'] + '">').one('load', () => {
        this.data[this.loadSequenceNum]['isLoadComplete'] = true;
        this.loadSequence();
      }).load();
    }
  }

  start() {
    this.pageApp.fadein();
    $('.TopSlideBg').append($('<div class="TopSlideBgImg" style="background-image:url(' + this.data[0]['img'] + ')">'));
    setTimeout(() => {
      $('.SubMenuContact').css('opacity', 1);
      this.next();
    }, 800);
  }

  next() {
    clearInterval(this.nextTimer);
    $('.SlideInfoProgress').addClass('-reset');

    // 次の画像をセットする(まだフェードインはさせない)
    if(this.currentImg == this.data.length - 1) {
      $('.TopSlideBg').append($('<div class="TopSlideBgImg" style="background-image:url(' + this.data[0]['img'] + ')">'));
    } else {
      $('.TopSlideBg').append($('<div class="TopSlideBgImg" style="background-image:url(' + this.data[this.currentImg + 1]['img'] + ')">'));
    }

    // 2枚以上だったら先頭を消す
    if($('.TopSlideBgImg').length > 3) {
      $('.TopSlideBgImg.-fadein:first').remove();
    }

    $('.SlideInfoArea, .SlideInfoTitle').stop(false).css('opacity', 0);

    // $('.SlideInfoArea__name').text(this.data[this.currentImg]['area']);
    // $('.SlideInfoArea__address').text(this.data[this.currentImg]['address']);
    // $('.SlideInfoArea').text(this.data[this.currentImg]['description']);
    /*
    $('.SlideInfoArea').empty();
    for(let i = 0; i < this.data[this.currentImg]['description'].length; i++) {
      let pushString = '<span>' + this.data[this.currentImg]['description'][i] + '</span>';
      $('.SlideInfoArea').append($(pushString));
    }*/
    $('.SlideInfoArea').html(this.data[this.currentImg]['description']);


    $('.SlideInfoTitle').text(this.data[this.currentImg]['title']);
    $('.SlideInfoPager__current').text(this.currentImg + 1);

    $('.SlideInfoArea').animate({'opacity': 1}, 700, 'easeInOutSine');
    $('.SlideInfoTitle').delay(200).animate({'opacity': 1}, 700, 'easeInOutSine');

    this.fadeinMap(this.currentImg);
    // 先頭の画像をフェードインさせる
    setTimeout(() => {
      $('.TopSlideBg').find('.TopSlideBgImg:not(.-fadein):first').addClass('-fadein');
      $('.SlideInfoProgress').removeClass('-reset').addClass('-start');

    }, 10);
    // $('.TopSlideBg').css('opacity', 1);
    $('.TopSlideBg').addClass('-fadein');
    $('.TopSlideOverlay').addClass('-fadein');

    this.currentImg++;
    if(this.currentImg == this.data.length) {
      this.currentImg = 0;
    }

    this.nextTimer = setInterval(() => {
      clearInterval(this.nextTimer);

      // 8秒後に次
      this.next();
    }, 8000);
  }


  fadeinMap(targetIndex) {
    this.mapIndex++;
    $('.SlideInfoMap').find('img').each((i, e) => {
      if(parseInt($(e).attr('data-index')) == targetIndex) {
        $(e).stop(false).css({
          'display': 'block',
          'opacity': 0,
          'z-index': this.mapIndex
        });
        $(e).delay(10).animate({'opacity': 1}, 500, 'linear');
      } else {
        if($(e).css('display') == 'block') {
          $(e).animate({'opacity': 0}, 500, 'linear', () => {
            $(e).css('display', 'none');
          });
        }
      }
    });
  }
}


export default TopSlide;
