import jQuery from 'jquery';
const $ = jQuery;

class SynergyController {
  constructor() {
    /*
    if($('body').hasClass('home')) {
      $('#synergy-ext1 option:first-child').html('職種を選択');
    }
    */

    if(String(location.href).indexOf('/join/') != -1 && $('#synergy-ext1').length) {
      if(String(location.href).indexOf('/owner') != -1) {
        $("#synergy-ext1").val('古民家等の活用について');
      } else if(String(location.href).indexOf('/area-manager') != -1) {
        $("#synergy-ext1").val('求人・エリアマネージャーについて');
      } else if(String(location.href).indexOf('/vendor') != -1) {
        $("#synergy-ext1").val('開業・出店について(物件をお探しの方)');
      } else if(String(location.href).indexOf('/craftsmanship') != -1) {
        $("#synergy-ext1").val('古民家の修復、再生について(建築士・工務店の方)');
      } else if(String(location.href).indexOf('/investment') != -1) {
        $("#synergy-ext1").val('寄付、投資について');
      }
    }

    this.url_template = 'http://nipponia.localhost/cms/wp-content/themes/nipponia';
    if(String(location.href).indexOf('team') != -1) {
      this.url_template = 'https://team.nipponia.or.jp/cms/wp-content/themes/nipponia';
    }

    this.url_api = this.url_template + '/scripts/synergy/SynergyApi-sample-customer-4/create_custom.php?';
  }

  regist() {

    let query = {};
    if($('input[name="synergy-name"]').val() != '') {
      query['ext3_set']   = 'true';
      query['ext3']       = $('input[name="synergy-name"]').val();
    }

    if($('body').hasClass('home')) {
      query['ext3_set']   = 'true';
      query['ext3']       = 'ニュースレター配信希望ユーザー(サイトトップページより)';
    }

    if($('input[name="synergy-mailaddress"]').val() != '') {
      query['mailaddress_set']  = 'true';
      query['mailaddress']      = $('input[name="synergy-mailaddress"]').val();
    }

    if($('select[name="synergy-ext1"]').length && $('select[name="synergy-ext1"] option:selected').val() != undefined) {
      query['ext16_set']   = 'true';
      query['ext16']       = $('select[name="synergy-ext1"] option:selected').val();
    }

    if($('textarea[name="id:your-message"]').length) {
      query['ext17_set']   = 'true';
      query['ext17']       = $('textarea[name="id:your-message"]').val();
    }

    if($('input[name="synergy-refusalflag"]').prop('checked') == false) {
      query['refusalflag_set']  = 'true';
      query['refusalflag']      = 'true';
    }

    query['needtrace'] = 'false';

    // console.log(query);

    let query_string = '';
    for(let key in query) {
      if(query_string != '') {
        query_string += '&';
      }
      query_string += key + '=' + encodeURI(query[key])
    }

    let url = this.url_api + query_string;

    $.ajax({
      url     : url,
      type    : 'GET',
      timeout : 5000,
    })
    .done(() => {
    })
    .fail(() => {
    });
  }
}

export default SynergyController;
