/*globals $ */

// import slick from 'slick-carousel';

class SlickSlider {
  constructor(app) {
    this.app = app;

    this.carousels = [];


    // this.init();


    this.prevViewMode = '';

    $(window).on('resize', () => {
      this.onWindowResize();
    });
    this.onWindowResize();
  }

  init() {
    $('.slick-slider').each((i, e) => {
      let _carousel   = $(e);
      let _options      = {};

      if(_carousel.attr('data-slick') == 'pickup') {
        _options = {
          autoplay         : false,
          autoplaySpeed    : 3000,
          arrows           : true,
          // slidesToShow     : 1,
          slidesToShow: 3,
          slidesToScroll   : 3,
          dots: true,
          infinite: false,
          pauseOnHover: false,
          /*
          responsive: [
            {
              breakpoint: 1130,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
          ]*/

        };

        if(this.app.viewMode == 'sp') {
          _options = {
            autoplay         : false,
            autoplaySpeed    : 3000,
            arrows           : false,
            // slidesToShow     : 1,
            slidesToShow: 1,
            slidesToScroll   : 1,
            dots: true,
            infinite: false,
            adaptiveHeight: true
            // pauseOnHover: false,
          };
        }
      } else if(_carousel.attr('data-slick') == 'featured-project') {
        _options = {
          autoplay         : false,

          arrows           : false,
          // slidesToShow     : 1,
          slidesToShow: 3,
          slidesToScroll   : 3,
          dots: false,
          infinite: false,
          pauseOnHover: false,
          /*
          responsive: [
            {
              breakpoint: 1130,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
          ]*/

        };

        if(this.app.viewMode == 'sp') {
          _options = {
            autoplay         : false,
            // autoplaySpeed    : 3000,
            arrows           : false,
            // slidesToShow     : 1,
            slidesToShow: 1,
            slidesToScroll   : 1,
            dots: true,
            infinite: false,
            // adaptiveHeight: true
            // pauseOnHover: false,
          };
        }
      } else if(_carousel.attr('data-slick') == 'people') {
        _options = {
          autoplay         : false,
          autoplaySpeed    : 3000,
          arrows           : true,
          // slidesToShow     : 1,
          slidesToShow: 3,
          slidesToScroll   : 3,
          dots: true,
          infinite: false,
          pauseOnHover: false

        };

        if(this.app.viewMode == 'sp') {
          _options = {
            autoplay         : false,
            // autoplaySpeed    : 3000,
            arrows           : false,
            // slidesToShow     : 1,
            slidesToShow: 1,
            slidesToScroll   : 1,
            dots: true,
            infinite: false,
            adaptiveHeight: true
            // adaptiveHeight: true
            // pauseOnHover: false,
          };
        }
      } else if(_carousel.attr('data-slick') == 'gallery') {
        _options = {
          autoplay         : false,
          autoplaySpeed    : 3000,
          arrows           : true,
          // slidesToShow     : 1,
          slidesToShow: 4,
          slidesToScroll   : 4,
          dots: true,
          infinite: true,
          pauseOnHover: false

        };

        if(this.app.viewMode == 'sp') {
          _options = {
            autoplay         : false,
            autoplaySpeed    : 3000,
            arrows           : false,
            // slidesToShow     : 1,
            slidesToShow: 1,
            slidesToScroll   : 1,
            dots: true,
            infinite: false,
            adaptiveHeight: true
            // pauseOnHover: false,
          };
        }


      } else if(_carousel.attr('data-slick') == 'publications') {
        _options = {
          autoplay         : false,
          autoplaySpeed    : 3000,
          arrows           : true,
          // slidesToShow     : 1,
          slidesToShow: 6,
          slidesToScroll   : 1,
          dots: false,
          infinite: false,
          pauseOnHover: false

        };


        if(this.app.viewMode == 'sp') {
          _options = {
            autoplay         : false,
            // autoplaySpeed    : 3000,
            arrows           : false,
            // slidesToShow     : 1,
            slidesToShow: 1,
            slidesToScroll   : 1,
            dots: true,
            infinite: false,
            adaptiveHeight: true
            // adaptiveHeight: true
            // pauseOnHover: false,
          };
        }
      } else if(_carousel.attr('data-slick') == 'project-related') {
        _options = {
          autoplay         : true,
          autoplaySpeed    : 4000,
          arrows           : false,
          slidesToShow: 1,
          slidesToScroll   : 1,
          dots: true,
          adaptiveHeight: true,
          infinite: true,
          pauseOnHover: false

        };

        if(this.app.viewMode == 'sp') {
          _options = {
            autoplay         : false,
            arrows           : false,
            slidesToShow: 1,
            slidesToScroll   : 1,
            dots: true,
            adaptiveHeight: true,
            infinite: false

          };
        }


      } else if(_carousel.attr('data-slick') == 'simple') {
        _options = {
          autoplay         : true,
          autoplaySpeed    : 3000,
          arrows           : false,
          // slidesToShow     : 1,
          slidesToShow: 1,
          slidesToScroll   : 1,
          dots: false,
          infinite: true,
          pauseOnHover: false

        };
      }




      /*
      _carousel.on('init', () => {
      }).on('breakpoint', () => {
      }).slick(_options);
      */
      _carousel.slick(_options);

      this.carousels.push(_carousel);

      /*
      if(_carousel.attr('data-slick') == 'single') {
        $('.ImageViewerNav__item').on('click', (e) => {
          _carousel.slick('slickGoTo', parseInt($(e.currentTarget).attr('data-index')));
        });

        _carousel.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
          $('.ImageViewerNav__item').each((i, e) => {
            if($(e).attr('data-index') == String(nextSlide)) {
              $(e).addClass('current')
            } else {
              $(e).removeClass('current')
            }
          });

          $('.ImageViewer__caption p').each((i, e) => {
            if($(e).attr('data-index') == String(nextSlide)) {
              $(e).addClass('current')
            } else {
              $(e).removeClass('current')
            }
          });

        });
      }*/

    });
  }

  onWindowResize() {

    if(this.prevViewMode != this.app.viewMode) {

      if(this.carousels.length > 0) {
        for(let i = 0; i < this.carousels.length; i++) {
          this.carousels[i].slick('unslick');
          this.carousels[i].addClass('slick-slider');
        }
        this.carousels = [];
        this.init();
      } else {
        this.init();
      }

      this.prevViewMode = this.app.viewMode;
    }
    /*
    $('.slick-slider[data-slick="recommend"]').each((i, e) => {
      let _gridWidth = $(e).find('.slick-slide').width() - 20;
      $(e).find('button').css('top', Math.floor(_gridWidth / 1.53 / 2));
    });
    */
  }
}

export default SlickSlider;
