/*globals $ */

import ProjectsMap from './ProjectsMap';

class AreaPageController {
  constructor(app) {
    this.app = app;

    this.currentMode = 'list';

    this.gridView = $('.AreasGridView');
    this.mapView = $('.AreasMapView');

    this.$container = $('.AreasContent');

    this.$nextView = undefined;
    this.$prevView = undefined;

    this.initMap = false;

    $('.AreasViewSwitcher button').on('click', (e) => {

      if($(e).attr('data-type') != this.currentMode) {
        this.changeView($(e.currentTarget).attr('data-type'));
      }

    });
  }

  changeView(target) {

    this.currentMode = target;
    $('.AreasViewSwitcher button').each((i, e) => {
      if($(e).attr('data-type') == this.currentMode) {
        $(e).addClass('-current');
      } else {
        $(e).removeClass('-current');
      }
    });


    if(this.currentMode == 'list') {
      this.$nextView = this.gridView;
      this.$prevView = this.mapView;
    } else if(this.currentMode == 'map') {
      this.$nextView = this.mapView;
      this.$prevView = this.gridView;
    }

    if(this.currentMode == 'list') {
      if(this.app.viewMode == 'pc') {
        this.$container.css('height', $(window).width() / 2);
      } else if(this.app.viewMode == 'sp') {
        this.$container.css('height', $(window).width());
      }

    } else if(this.currentMode == 'map') {
      this.$container.css('height', this.$prevView.height());
    }

    this.$prevView.stop(false).animate({'opacity':0}, 400, 'easeInOutSine', () => {

      this.$prevView.css('display', 'none');
      this.$nextView.css({'display':'block', 'opacity':0});

      if(this.initMap == false && this.$nextView.hasClass('AreasMapView')) {
        this.initMap = true;
        new ProjectsMap(this.app, true);
      }

      setTimeout(() => {
        let nextHeight = this.$nextView.height();
        // console.log(this.$nextView.height());
        if(this.$nextView.hasClass('AreasMapView')) {
          if(this.app.viewMode == 'pc') {
            nextHeight = $(window).width() / 2;
          } else if(this.app.viewMode == 'sp') {
            nextHeight = $(window).width();
          }

        }
        this.$container.animate({'height': nextHeight}, 300, 'easeInOutSine', () => {
          this.$nextView.animate({'opacity': 1}, 300, 'easeInOutSine', () => {
            this.$container.css('height', 'auto');
          });
        });
      }, 100);




    });


  }
}

export default AreaPageController;
